import React, {useEffect, useState} from "react";
import {getAllUsers} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {usersScheme} from "../../UI/Tables/dash_scheme";
export default function AllUsers({token}) {

    const [, setCompanies] = useState(null); //companies
    const [allUsers, setAllUsers] = useState(null);

    useEffect(() => {

        const getAllUsersHandler = async () => {

            const updateAllUsers = await getAllUsers(token);
            // console.log('\n getAllUsersHandler updateAllUsers', updateAllUsers);
            setCompanies(updateAllUsers.companies);
            const updateUsers = updateAllUsers?.users?.map(user => {

                user.company_name = updateAllUsers?.companies?.find(company => company.id === user.company_id)?.name;
                user.token = token;

                return (user);
            });
            // console.log('\n updateUsers', updateUsers);
            setAllUsers(updateUsers);
        }

        getAllUsersHandler();
    }, [token]);

    return (allUsers && <DashTable
        data={allUsers}
        label={"Список всех пользователей"}
        columns={usersScheme}
    />);
}
