import React, {useEffect, useState} from "react";
import {getAllProjects} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {projectsScheme} from "../../UI/Tables/dash_scheme";
export default function AllProjects({token}) {

    const [, setCompanies] = useState(null); //companies
    const [allProjects, setAllProjects] = useState(null);

    useEffect(() => {

        const getAllProjectsHandler = async () => {

            const updateAllProjects = await getAllProjects(token);
            // console.log('\n getAllProjectsHandler updateAllUsers', updateAllProjects);
            setCompanies(updateAllProjects.companies);
            setAllProjects(updateAllProjects?.projects.map(p => ({...p, token})));
        }

        getAllProjectsHandler();
    }, [token]);

    // console.log('\n allProjects', allProjects);

    return (allProjects && <DashTable
        data={allProjects}
        label={"Список всех проектов"}
        columns={projectsScheme}
    />);
}
