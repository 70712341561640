import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getAllDocuments} from "../../methods/subcompaniesMethods";
import DashTable from "../../UI/Tables/DashTable";
import {Button} from "@mui/material";
import {api, fileServer, headersRequests} from "../../methods/Settings";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {ErrorOccurred} from "../../methods/ErrorOccurred";
import axios from "axios";

export default function SubcompaniesEditDocuments ({token}) {

    const contractData = new URLSearchParams(useLocation().search);
    const type_id = +contractData.get('type');
    const tender_id = +contractData.get('tender');
    const labels = {
        1: "Изменить документы при регистрации",
        2: "Изменить акты передачи строительного объекта",
        3: "Изменить документы о качестве выполненных работ",
        4: "Изменить акты выполненных работ",
    };

    const [currentTender, setCurrentTender] = useState(null);
    const subcompany_id = currentTender?.tender?.subcompany;
    const company_id = currentTender?.tender?.company_id;
    const project_id = currentTender?.tender?.project_id;

    const [documents, setDocuments] = useState(null);
    const [answer, setAnswer] = useState(null);

    const deleteRegisterDocs = async (register_docs_id) => {

        try {

            setAnswer(null);

            const request = await axios.delete(
                `${api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/${register_docs_id}/`,
                headersRequests('post', token)
            )

            // console.log('\n deleteRegisterDocs', register_docs_id, request);
            if (request.status === 204) window.location.reload();
        }
        catch (errors) {
            const error = ErrorOccurred(errors.response, 'Ошибка удаления документов регистрации', 'deleteRegisterDocs');
            console.log('\n error', error);
            setAnswer(error.jsx);
        }
    }
    const deleteRegisterActs = async (register_act_id) => {

        try {

            setAnswer(null);

            const request = await axios.delete(
                `${api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/${register_act_id}/`,
                headersRequests('post', token)
            )

            // console.log('\n deleteRegisterActs', register_act_id, request);
            if (request.status === 204) window.location.reload();
        }
        catch (errors) {
            const error = ErrorOccurred(errors.response, 'Ошибка удаления актов передачи объекта', 'deleteRegisterActs');
            console.log('\n error', error);
            setAnswer(error.jsx);
        }
    }
    const deleteQualityDocs = async (quality_document_id) => {

        try {

            setAnswer(null);

            const request = await axios.delete(
                `${api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_id}/delete-by-super-admin`,
                headersRequests('post', token)
            )

            // console.log('\n deleteQualityDocs', quality_document_id, request);
            if (request.status === 204) window.location.reload();
        }
        catch (errors) {
            const error = ErrorOccurred(errors.response, 'Ошибка удаление документа о качестве', 'deleteQualityDocs');
            console.log('\n error', error);
            setAnswer(error.jsx);
        }
    }

    const deleteCertificatesDocs = async (certificate_id) => {

        try {

            setAnswer(null);

            const request = await axios.delete(
                `${api}/work_payment/companies/${company_id}/projects/${project_id}/subcompanies/${subcompany_id}/tenders/${tender_id}/work-payment-certificates/${certificate_id}/delete-by-super-admin`,
                headersRequests('post', token)
            )

            if (request.status === 204) window.location.reload();
        }
        catch (errors) {
            const error = ErrorOccurred(errors.response, 'Ошибка удаление документа о качестве', 'deleteQualityDocs');
            console.log('\n error', error);
            setAnswer(error.jsx);
        }
    }


    useEffect(() => {
        const getDocumentsHandler = async () => {

            const updateDocuments = await getAllDocuments(token);
            const updateCurrentTender = updateDocuments?.find(t => t.id === tender_id);
            if (updateCurrentTender) {

                setCurrentTender(updateCurrentTender);
                // console.log('\n updateCurrentTender', updateCurrentTender);
                if (type_id === 1) {
                    setDocuments(updateCurrentTender.register_documents);
                }
                else if (type_id === 2) {
                    setDocuments(updateCurrentTender.register_acts);
                }
                else if (type_id === 3) {
                    setDocuments(updateCurrentTender.quality_documents);
                }
                else if (type_id === 4) {
                    setDocuments(updateCurrentTender.work_payment_certificates);
                }
            }
        }

        getDocumentsHandler();
    },[token, tender_id, type_id]);

    console.log('\n type_id', type_id);
    // console.log('\n tender_id', tender_id);
    // console.log('\n currentTender', currentTender);
    console.log('\n documents', documents);

    const editDocsScheme = [
        {
            "field": "id",
            "headerName": "ID",
            "type": "number",
            headerAlign: 'center',
            align: 'center',
        },
        {
            "field": "file",
            "headerName": "Файл",
            "headerAlign": "center",
            "flex": 1,
            "align": "center",
            "renderCell": (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<FilePresentOutlinedIcon />}
                        color="info"
                        component={Link}
                        to={`${fileServer}${params.value ? params.value : params.row.document}`}
                        target="_blank"
                    >
                        {params.row.name ? params.row.name : params.row?.work_journal?.estimate?.find(wj => wj.id === params.row?.work_journal_element_id)?.name_works}
                    </Button>
                );
            },
        },
        ...(type_id === 1 ? [{
            "field": "is_declined",
            "headerName": "Отклонен",
            "headerAlign": "center",
            "align": "center",
            "flex": 1,
            "renderCell": (params) => {
                return (
                    params.row.is_declined ?
                        <span className={"name-column--cell"}>"{params.row.reason}"</span> :
                        <span>Нет</span>
                );
            },
        }] : []),
        ...(type_id === 3 ? [{
            "field": "is_approved",
            "headerName": "Согласован",
            "headerAlign": "center",
            "align": "center",
            "flex": 1,
            "renderCell": (params) => {
                return (
                    params.row.is_approved ?
                        <span className={"name-column--cell"}>Согласован</span> :
                        <span>"{params.row.reason}"</span>
                );
            },
        }] : []),
        {
            "field": "delete",
            "headerName": "Удалить",
            "headerAlign": "center",
            "align": "center",
            "flex": 0.4,
            "renderCell": (params) => {
                console.log('\n del params', params);
                return (
                    <Button
                        variant="contained"
                        startIcon={<DeleteRoundedIcon />}
                        color="error"
                        onClick={() => {
                            if (type_id === 1) deleteRegisterDocs(params.row.id);
                            else if (type_id === 2) deleteRegisterActs(params.row.id);
                            else if (type_id === 3) deleteQualityDocs(params.row.id);
                            else if (type_id === 4) deleteCertificatesDocs(params.row.id);
                        }}
                    >
                        Удалить
                    </Button>
                );
            },
        },
    ];

    return (
        documents && <DashTable
            data={documents}
            label={labels[type_id]}
            subtitle={currentTender && `Тендер ${currentTender.tender_name}, Компания ${currentTender.tender_company_name}, Подрядчик ${currentTender.tender_subcompany_name}`}
            columns={editDocsScheme}
            link={{
                icon: <ArrowBackRoundedIcon />,
                text: "Назад",
                to:`${process.env.PUBLIC_URL}/subcompanies-documents/`
            }}
            answer={answer}
        />
    );
}